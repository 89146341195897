<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <b-form-group
            class="mr-1 mb-md-0"
          >
          <!-- <b-button
            v-b-modal.modal-1
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="addData"
          >
            เติมเครดิต
          </b-button> -->
          </b-form-group>
          <!-- <div>
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <b-form-select
                    v-model="search_type"
                    class="d-inline-block mr-1"
                    :options="search_type_option"
                  />
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    block
                    @click="getSMS()"
                  >
                    <feather-icon icon="SearchIcon" />
                    Search
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div> -->
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(bank)="data">
          <div v-if="data.item.resource === 'cryptostate'">
            USDT
          </div>
          <div v-else>
            {{ data.item.bank }}
          </div>
        </template>
        <template #cell(accno)="data">
          <div v-if="data.item.resource === 'cryptostate'">
            {{ data.item.from }}
          </div>
          <div v-else>
            {{ data.item.accno }}
          </div>
        </template>
        <template #cell(amount)="data">
          <div v-if="data.item.resource === 'cryptostate'">
            {{ data.item.thb }}
          </div>
          <div v-else>
            {{ data.item.amount }}
          </div>
        </template>
        <template #cell(timeshow)="data">
          <div v-if="data.item.resource === 'kbankstate'">
            {{ data.item.transDate }}
          </div>
          <div v-else-if="data.item.resource === 'cryptostate'">
            {{ FormatDate(data.item.datetime) }}
          </div>
          <div v-else>
            {{ data.item.time }}
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(topup)="data">
          <div class="text-nowrap">
            <feather-icon
              v-if="data.item.status === 'waiting'"
              :id="`invoice-row-${data.item.id}-preview-icon`"
              v-b-modal.modal-1
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="topup(data.item)"
            />
          </div>
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        id="modal-1"
        title="เติมเครดิต"
        ok-title="เติมเครดิต"
        @ok="submit"
      >
        <!-- <b-form-group>
          <v-select
            v-model="member"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="names"
            :options="optionsMember"
          />
        </b-form-group> -->
        <div class="d-flex">
          <b-form-input
            id="vi-first-name"
            v-model="tel"
            class="col-9 mr-1"
            name="tel"
            type="number"
            placeholder="กรอกเบอร์โทรศัพท์แล้วกดค้นหา"
          />
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="col-2.5 btn-icon"
            block
            @click="getUsersSearch()"
          >
            <feather-icon icon="SearchIcon" />
            ค้นหา
          </b-button>
        </div>
        <div>
          <span
            v-if="!member && search === true"
            style="color :red;"
          >
            ไม่พบข้อมูล
          </span>
          <span
            v-if="member && search === true"
            style="color :green;"
          >
            {{ member.name }} {{ member.surname }} : {{ member.username }}
          </span>
        </div><br>
        <b-form-group
          label="จำนวนเงินที่เติม"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <i class="fad fa-donate" />
            <!-- <feather-icon icon="CheckSquareIcon" /> -->
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              v-model="amount"
              placeholder=""
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
import moment from 'moment-timezone'
import {
  // BRow, BCol,
  BInputGroupPrepend, BModal, BCard, BTable,
  // BAvatar,
  BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput,
  BButton,
  BCardBody, VBToggle, BOverlay, BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // vSelect,
    // BRow,
    // BCol,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      search_type_option: [
        { value: 1, text: 'สถานะรอเติมเงิน' },
        { value: 2, text: 'สถานะเติมเงินสำเร็จ' },
        { value: 3, text: 'ทั้งหมด' },
      ],
      search_type: null,
      search_val: null,
      show: true,
      depositdata: [],
      member: '',
      amount: '',
      trueData: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'resource', label: 'resource' },
        { key: 'bank', label: 'bank' },
        { key: 'accno', label: 'accno' },
        { key: 'amount', label: 'amount' },
        { key: 'timeshow', label: 'เวลาตามสลิป' },
        { key: 'status', label: 'status' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'topup', label: 'เติมเครดิต' },

      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      datafield: [],
      show2: false,
      tel: null,
      search: false,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.getSMS()
    }, 30000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    this.getSMS()
    // this.getUserslist()
  },
  methods: {
    getUsersSearch() {
      this.show2 = true
      const obj = {
        tel: this.tel,
      }
      this.$http
        .post('/users/search', obj)
        .then(response => {
          this.member = response.data
          this.search = true
          this.show2 = false
        })
        .catch(error => console.log(error))
    },
    topup(data) {
      this.trueData = data
      this.amount = data.amount
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getUserslist() {
      this.$http
        .get('/users/list')
        .then(response => {
          this.optionsMember = response.data
        })
        .catch(error => console.log(error))
    },
    async getSMS() {
      this.datafield = []
      this.$http
        .get('/statement/waitinglist')
        .then(response => {
          this.show = false
          // console.log(response.data.kbanksms)
          response.data.kbanksms.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.resource = 'kbank'
          })
          response.data.scbsms.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.resource = 'scbsms'
          })
          response.data.scbconnect.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.resource = 'scbconnect'
          })
          response.data.scbstate.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.resource = 'scbstate'
          })
          response.data.kbankstate.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.resource = 'kbankstate'
          })
          response.data.crypto_state.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.resource = 'cryptostate'
          })
          // data.push(response.data.scbsms)

          this.datafield.push(...response.data.scbsms)
          this.datafield.push(...response.data.scbconnect)
          this.datafield.push(...response.data.kbanksms)
          this.datafield.push(...response.data.scbstate)
          this.datafield.push(...response.data.kbankstate)
          this.datafield.push(...response.data.crypto_state)
          // eslint-disable-next-line no-undef
          const datasort = this.datafield.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).reverse()
          this.onFiltered(datasort)
          // console.log(this.datasort)
        })
        .catch(error => console.log(error))
    },
    submit() {
      // console.log(this.trueData)
      this.show = true
      const formData = {
        username: this.member.username,
        uid: this.member.id,
        amount: this.amount,
        time: this.trueData.time,
        resource: this.trueData.resource,
        // eslint-disable-next-line no-underscore-dangle
        resource_id: this.trueData._id,
        currency: 'THB',
      }
      if (this.trueData.resource === 'scbstate' || this.trueData.resource === 'scbconnect') {
        formData.from = 'statement'
        formData.bank = 'scb'
      } else if (this.trueData.resource === 'scbsms') {
        formData.from = 'scbsms'
        formData.bank = 'scb'
      } else if (this.trueData.resource === 'kbank' || this.trueData.resource === 'kbankstate') {
        formData.from = 'kbankstatement'
        formData.bank = 'kbank'
        formData.time = moment(this.trueData.transDate).format('HH:mm')
      }
      this.$http
        .post('/deposit/store', formData)
        .then(() => {
          this.show = false
          this.member = ''
          this.trueData = ''
          this.amount = ''
          this.getSMS()
          this.Success('เติมเครดิต สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    FormatDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
